.transactionCountContainer {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 24%;
  padding: 10px 15px;
}
.TransactionAmount {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 28%;
  padding: 15px 15px;
  position: relative;
}
.TransactionSettlements {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 48%;
  padding: 15px 15px;
  position: relative;
  margin: 50px 0px;
}
.livehelp {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
}
.TransactionStats {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 23%;
  padding: 40px 20px;
  position: relative;
  min-height: 190px;
  max-height: 190px;
  height: 100%;
}
.TransactionCounts {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 42%;
  padding: 5px 10px;
  position: relative;
  /* margin: 50px 0px; */
  /* margin */
}
.TransactionCategory {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 23%;
  padding: 40px 20px;
  position: relative;
  min-height: 60px;
  max-height: 60px;
  height: 60px;
}
.eachCount {
  /* margin: 0px 5px; */
}
.secondCount {
  width: 100%;
}
